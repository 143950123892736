import { Col, Container, Row } from "reactstrap";
import Link from "next/link";

//local imports
import classes from "@/styles/generic/footer.module.css";
import { footer } from "@/utils/content";
import { routes } from "@/utils/routes";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

export default function Footer() {
  return (
    <div className={`${classes.container}`}>
      <Container>
        <div className={classes.grid}>
          <div>
            <h5 className={classes.heading}>{footer.en.resources}</h5>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.openAccountOffer}>
                  {footer.en.openAccountOffer}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.howItWorks}>
                  {footer.en.howItWorks}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.connectTools}>
                  {footer.en.shipvistaForPlatforms}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.documentToolkit}>
                  {footer.en.documentToolkit}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.quickVideos}>
                  {footer.en.quickVideos}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.features}>
                  {footer.en.features}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h5 className={classes.heading}>{footer.en.developers}</h5>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.developers}>
                  {footer.en.overview}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.apiStatus}>
                  {footer.en.apiStatus}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h5 className={classes.heading}>{footer.en.partners}</h5>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.technologyPartner}>
                  {footer.en.technologyPartners}
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  className={classes.link}
                  href={routes.fulfillmentPartners}
                >
                  {footer.en.fulfillmentPartners}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.carriers}>
                  {footer.en.otherCarriers}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.becomeAPartner}>
                  {footer.en.becomeAPartner}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.becomeAnAffiliate}>
                  {footer.en.becomeAnAffiliate}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h5 className={classes.heading}>{footer.en.company}</h5>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.about}>
                  {footer.en.aboutUs}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.careers}>
                  {footer.en.careers}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.newsroom}>
                  {footer.en.newsroom}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.terms}>
                  {footer.en.termsOfUse}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.privacyPolicy}>
                  {footer.en.privacyPolicy}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h5 className={classes.heading}>{footer.en.contact}</h5>
            <ul className={classes.ul}>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.help}>
                  {footer.en.helpCenter}
                </Link>
              </li>
              <li className={classes.li}>
                <Link className={classes.link} href={routes.contactMain}>
                  {footer.en.contact}
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  className={classes.link}
                  href={routes.creditCardConsentAgreement}
                >
                  {footer.en.creditCardConsentAgreement}
                </Link>
              </li>
              <li className={classes.li}>
                <Link
                  className={classes.link}
                  href={routes.commissionProgramAgreement}
                >
                  {footer.en.commissionProgramAgreement}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.footerSocialGrid}>
          <div>
            <Link
              href={footer.en.usditlink}
              target="_blank"
              className={classes.link2}
            >
              {footer.en.usdotno}
            </Link>
          </div>
          <div className={classes.socialLinks}>
            <div className={classes.iconWrapper}>
              <Link
                href={footer.en.twitterLink}
                aria-label="Shipvista Twitter Account"
              >
                <FaTwitter color="white" />
              </Link>
            </div>
            <div className={classes.iconWrapper}>
              <Link
                href={footer.en.facebookLink}
                aria-label="Shipvista Facebook Page"
              >
                <FaFacebook color="white" />
              </Link>
            </div>
            <div className={classes.iconWrapper}>
              <Link
                href={footer.en.instagramLink}
                aria-label="Shipvista Instagram Page"
              >
                <FaInstagram color="white" />
              </Link>
            </div>
            <div className={classes.iconWrapper}>
              <Link
                href={footer.en.linkedinLink}
                aria-label="Shipvista LinkedIn Page"
              >
                <FaLinkedin color="white" />
              </Link>
            </div>
            <div className={classes.iconWrapper}>
              <Link
                href={footer.en.youtubeLink}
                aria-label="Shipvista YouTube Channel"
              >
                <FaYoutube color="white" />
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <div className={classes.footerSec2}>
        <Container>
          <div className={classes.footerSec2Grid}>
            <p>{footer.en.copyright}</p>
            <div className={classes.link3Wrapper}>
              <Link className={classes.link3} href={routes.sitemap}>
                {footer.en.sitemap}
              </Link>
              <Link className={classes.link3} href={routes.personalInfoForm}>
                {footer.en.doNotSellMyPersonalInfo}
              </Link>
            </div>
            <p>
              <strong>{footer.en.poweredBy}</strong>&nbsp;
              <Link href={routes.home} className={`${classes.link3}`}>
                {footer.en.shipvistacom}
              </Link>
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
}
