import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { googleAnalyticsId, googleTagManagerId } from "./constants";

let loadedGoogleAnalytics = false;
let loadedGoogleTagManager = false;

export function initGoogleAnalytics() {
  // Enable debug mode on the local development environment
  const isDev = false; //!process.env.NODE_ENV || process.env.NODE_ENV === "development";

  if (loadedGoogleAnalytics) return true;

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId, { testMode: isDev });
    loadedGoogleAnalytics = true;
    return true;
  }

  return false;
}

export function initGTM() {
  const tagManagerArgs = {
    gtmId: googleTagManagerId ?? "",
  };

  if (loadedGoogleTagManager) return true;

  if (tagManagerArgs.gtmId) {
    TagManager.initialize(tagManagerArgs);
    loadedGoogleTagManager = true;
    return true;
  }

  return false;
}

export function sendEvent(payload: any) {
  ReactGA.event(payload);
}

export function sendPageView(path: string) {
  ReactGA.send({ hitType: "pageview", page: path });
}
