import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { routerEvents } from "@/utils/constants";
import {
  clearAccessTokens,
  accessTokenSessionKey,
  accessTokenSessionExpiryKey,
} from "@/utils/token-manager";
import { tokenCookieName, refreshTokenKey } from "@/utils/token-manager";
import { routes } from "@/utils/routes";

export const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    const expiresAt = window.localStorage.getItem("expireAt");

    if (accessToken && expiresAt) {
      if (new Date() < new Date(expiresAt)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (router.query.e && router.query.e === routerEvents.logout) {
      clearAccessTokens(
        tokenCookieName,
        accessTokenSessionKey,
        accessTokenSessionExpiryKey,
        refreshTokenKey
      );

      setIsAuthenticated(false);

      router.push(routes.home);
    }
  }, [router, router.query]);

  return isAuthenticated;
};
