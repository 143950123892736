import "bootstrap/dist/css/bootstrap.min.css"; // Import bootstrap CSS
import "@/styles/globals.css";
import "react-toastify/dist/ReactToastify.css";

import type { AppProps } from "next/app";
import Layout from "@/components/generic/layout";
import useGoogleAnalytics from "@/hooks/useGoogleAnalytics";
import Script from "next/script";
import {
  bingTrackingId,
  fbPixelId,
  microsoftClarityId,
  tikTokPixelId,
} from "@/utils/constants";
// import useDataDogRUM from "@/hooks/useDataDogRUM";

export default function App({ Component, pageProps }: AppProps) {
  useGoogleAnalytics();
  // useDataDogRUM();
  return (
    <Layout pageProps={pageProps}>
      <Component {...pageProps} />
      <Script id="metaPixelCode">
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fbPixelId}');
        fbq('track', 'PageView');`}
      </Script>

      <Script id="bindTrackingScript">
        {`(function (w, d, t, r, u) {
          var f, n, i;
          w[u] = w[u] || [], f = function () {
            var o = { ti: "${bingTrackingId}" };
            o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
          },
            n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
              var s = this.readyState;
              s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
            },
            i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
        })
          (window, document, "script", "//bat.bing.com/bat.js", "uetq");`}
      </Script>

      <Script id="microsoftClarityScripy">
        {`
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${microsoftClarityId}");
        `}
      </Script>
      <Script id="tikTokPixelScript">
        {`
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
      var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
      ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      
      
        ttq.load('${tikTokPixelId}');
        ttq.page();
      }(window, document, 'ttq');
      `}
      </Script>
    </Layout>
  );
}
