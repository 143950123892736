import React, { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import Footer from "@/components/generic/footer";
import NavBar from "@/components/generic/navbar";
import { UserAgentContext } from "@/context/user-agent-context";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";

interface LayoutProps {
  children: ReactNode;
  pageProps: any;
}

const Layout = ({ children, pageProps }: LayoutProps) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <UserAgentContext.Provider value={pageProps.isSsrMobile}>
        <NavBar isAuthenticated={isAuthenticated} />
        {children}
        <Footer />
        <ToastContainer
          position="top-center"
          autoClose={20000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
          draggable
          theme="colored"
        />
      </UserAgentContext.Provider>
    </>
  );
};

export default Layout;
