import { useContext } from "react";

import { UserAgentContext } from "@/context/user-agent-context";
import { useWindowSize } from "./useWindowSize";

export const useIsMobile = () => {
  const isSsrMobile = useContext(UserAgentContext);
  const { width: windowWidth } = useWindowSize();

  const isBrowserMobile = !!windowWidth && windowWidth < 992;

  return isSsrMobile || isBrowserMobile;
};
