import React, { useRef, useCallback, useEffect } from "react";
// import { useLocation, matchPath } from "react-router-dom";
import {
  initGTM,
  initGoogleAnalytics,
  sendEvent,
  sendPageView,
} from "@/utils/analytics";
import TagManager from "react-gtm-module";
import useUpdateEffect from "./useUpdateEffect";
import { useRouter } from "next/router";
// import { MENU_MAP } from "../components/masterPage/common";

type MandatoryParameters = {
  page: { url?: string };
};

type CustomEventType =
  | "registration"
  | "getRatesGuest"
  | "FTLGetRatesGuest"
  | "LTLGetRatesGuest"
  | "shipNowGuest"
  | "trackShipment"
  | "bannerPromoCodeToChangePlan"
  | "getRatesToCreateLabel"
  | "updatePlan"
  | "getRatesToCreateLabelOrders"
  | "connectStoreOrMarketplace";

// withoutGTM = without Google Tag Manager - GTM and GA4 scripts conflict with each other
export default function useGoogleAnalytics(
  { withoutGTM } = { withoutGTM: false }
) {
  const router = useRouter();
  const setupRef = useRef<boolean>(false);
  const setupRefGTM = useRef<boolean>(false);

  useEffect(() => {
    if (withoutGTM) {
      setupRef.current = initGoogleAnalytics();
    } else {
      setupRefGTM.current = initGTM();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    const currentPath =
      router.pathname + router.asPath.split("#")[1] + router.query;
    const fullUrl =
      document.location.origin +
      document.location.pathname +
      document.location.search;
    if (setupRef.current) {
      sendPageView(currentPath);
    }
    if (setupRefGTM.current) {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: {
            url: fullUrl,
            title: document.title,
          },
        },
      });
    }
  }, [router.pathname]);

  const sendCustomEvent = useCallback(
    (
      eventName: CustomEventType,
      params?: MandatoryParameters & { params?: object }
    ) => {
      if (eventName) {
        if (setupRef.current) {
          sendEvent({
            hitType: eventName,
            page: params?.page?.url,
          });
        }
        if (setupRefGTM.current) {
          const fullUrl =
            document.location.origin +
            document.location.pathname +
            document.location.search;
          TagManager.dataLayer({
            dataLayer: {
              page: {
                ...params?.page,
                params: params?.params,
                url: params?.page?.url ?? fullUrl,
              },
              event: eventName,
            },
          });
        }
      }
    },
    []
  );
  return {
    sendCustomEvent,
  };
}
