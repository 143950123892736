export type RefreshResult = { refreshToken: string; expiredAt: string };

const refreshExpiryPartialKey = "Expiry";
export const tokenCookieName = "sv_tkn";
export const refreshTokenKey = "refreshToken";
// Note: the below values of the variables might hardcoded in the MasterPage.tsx logout function
// and in the loginHandlerService.tsx
export const accessTokenSessionKey = "accessToken";
export const accessTokenSessionExpiryKey = "expireAt";

export const clearAccessTokens = (
  accessTokenCookieName: string,
  accessTokenSessionKey: string,
  accessTokenSessionExpiryKey: string,
  refreshTokenKey: string
) => {
  // Should be the same code as logout function in MasterPage but modified to provide the keys

  document.cookie = `${accessTokenCookieName}=`;
  window.localStorage.setItem(accessTokenSessionKey, "");
  window.localStorage.setItem(accessTokenSessionExpiryKey, "");

  setRefreshToken(refreshTokenKey, "", "");
};

export const setRefreshToken = (
  refreshTokenKey: string,
  refreshToken: string,
  expiry: string
): RefreshResult => {
  const oldValue = localStorage.getItem(refreshTokenKey);
  const oldValueExpiry = localStorage.getItem(
    refreshTokenKey + refreshExpiryPartialKey
  );
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(refreshTokenKey + refreshExpiryPartialKey, expiry);
  if (oldValue) {
    return { refreshToken: oldValue, expiredAt: oldValueExpiry || "" };
  } else {
    return { refreshToken: "", expiredAt: oldValueExpiry || "" };
  }
};
