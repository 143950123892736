import { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Image from "next/image";
import Link from "next/link";
import { BsArrowRightShort } from "react-icons/bs";
import { MdCall, MdMailOutline } from "react-icons/md";

//local imports
import classes from "@/styles/generic/navbar.module.css";
import { logo, smallLogo } from "@/utils/images";
import { routes } from "@/utils/routes";
import { navbar } from "@/utils/content";
import { useIsMobile } from "@/hooks/useIsMobile";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { dashboardUrl } from "@/utils/constants";

type Props = {
  isAuthenticated: boolean;
};

export default function NavBar({ isAuthenticated }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPoint, setScrollPoint] = useState(0);
  const [mobile, setMobile] = useState(false);
  const isMobile = useIsMobile();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPoint(window.scrollY);
    });
    setMobile(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.darkTopNav}></div>
      <div className={classes.navTop}>
        <div className="container">
          <div className={`${classes.flex} ${classes.jsB}`}>
            <div className={classes.flex}>
              <Link
                className={classes.navLink}
                href={`telto: ${navbar.en.contactNumber}`}
              >
                <MdCall className={classes.navIcon} />
                &nbsp;{navbar.en.contactNumber}
              </Link>
              <Link
                className={classes.navLink}
                href={`mailto: ${navbar.en.contactMail}`}
              >
                <MdMailOutline className={classes.navIcon} />
                &nbsp;{navbar.en.contactMail}
              </Link>
              <Link className={classes.navLink} href={routes.about}>
                {navbar.en.company}
              </Link>
            </div>
            <div className={classes.flex}>
              <Link className={classes.navLink} href={routes.contact}>
                {navbar.en.support}
              </Link>
              <Link className={classes.navLink} href={routes.blog}>
                {navbar.en.blog}
              </Link>
              <Link className={classes.navLink} href={routes.trackShipment}>
                {navbar.en.trackMyShipment}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        className={`${
          scrollPoint >= (mobile ? 107 : 60)
            ? classes.containerFixed
            : classes.container
        } sticky-top ${isMobile ? "p-0" : ""}`}
        color="light"
        light
        expand="md"
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <Collapse isOpen={isOpen} navbar>
            {!isMobile && (
              <NavbarBrand href="/">
                <Image
                  src={logo}
                  alt={navbar.en.logoAlt}
                  width={175}
                  height={40}
                  priority
                />
              </NavbarBrand>
            )}
            <Nav className="mx-auto" navbar>
              <NavItem>
                <Link className="nav-link" href={routes.getQuote}>
                  {navbar.en.getQuote}
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" href={routes.pricing}>
                  {navbar.en.pricing}
                </Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <div className="d-flex">
                  <Link className="nav-link pe-0" href={routes.partners}>
                    {navbar.en.partners}
                  </Link>
                  <DropdownToggle nav caret className="ps-0"></DropdownToggle>
                </div>
                <DropdownMenu>
                  <DropdownItem>
                    <Link
                      className="nav-link text-center"
                      href={routes.technologyPartner}
                    >
                      {navbar.en.technologyPartners}
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <Link
                      className="nav-link text-center"
                      href={routes.fulfillmentPartners}
                    >
                      {navbar.en.fulfillmentPartners}
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <Link
                      className="nav-link text-center"
                      href={routes.otherCarriers}
                    >
                      {navbar.en.otherCarriers}
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <Link
                      className="nav-link text-center"
                      href={routes.developers}
                    >
                      {navbar.en.developers}
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Link className="nav-link" href={routes.airmiles}>
                  {navbar.en.airmiles}
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" href={routes.insureshield}>
                  {navbar.en.UPSCapital}
                </Link>
              </NavItem>
            </Nav>
            {!isMobile && (
              <>
                {isAuthenticated ? (
                  <Link
                    className={`${classes.navBtn} nav-link`}
                    href={dashboardUrl + routes.dashboard}
                  >
                    Go To Dashboard{" "}
                    <BsArrowRightShort
                      size={20}
                      style={{ marginBottom: "3px" }}
                    />
                  </Link>
                ) : (
                  <>
                    <Link
                      className={`${classes.navBtn2} ${classes.navBtn2} nav-link pe-3`}
                      href={routes.login}
                    >
                      {navbar.en.login}
                    </Link>
                    <Link
                      className={`${classes.navBtn} nav-link`}
                      href={routes.register}
                    >
                      {navbar.en.signUp}
                    </Link>
                  </>
                )}
              </>
            )}
          </Collapse>
          {!isOpen && isMobile && (
            <>
              <NavbarBrand href="/">
                <Image
                  src={smallLogo}
                  alt={navbar.en.logoAlt}
                  width={60}
                  height={60}
                  priority
                />
              </NavbarBrand>
              <Nav className="d-inline text-end" navbar>
                <NavItem className="d-inline">
                  <Link
                    className={`${classes.navBtn2} ${classes.navBtn2} nav-link d-inline mx-2`}
                    href={routes.login}
                  >
                    {navbar.en.login}
                  </Link>
                </NavItem>
                <NavItem className="d-inline">
                  <Link
                    className={`${classes.navBtn} nav-link d-inline mx-2`}
                    href={routes.register}
                  >
                    {navbar.en.signUpShort}
                  </Link>
                </NavItem>
              </Nav>
            </>
          )}
          <NavbarToggler onClick={toggle} />
        </div>
      </Navbar>
    </>
  );
}
